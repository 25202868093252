import * as React from 'react';
import Component from '../../../Component';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import TBody from './TBody';
import THead from './THead';

export default class List extends Component {

    render() {
        //        const caches: any = this.getCaches().getState();
        //        let leaveTypesMap = caches.leaveTypes.map;
        const rows = this.props.rows;
        // tslint:disable-next-line:max-line-length
        //        const isAdmin = (this.getSession().getState() as any).companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        switch (rows.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                if (rows.arr.length === 0) {
                    return <div className="text-danger">
                        {t('no.leaves.found')}
                    </div>
                }
                return <div className="card w-100">
                    <div className="card-body">
                        <div className="table-responsive-sm">
                            <table className="table table-sm table-hover table-bordered w-100 m-0">
                                <THead days={this.props.days} leaveTypes={this.props.leaveTypes} />
                                <TBody rows={this.props.rows} leaveTypes={this.props.leaveTypes} />
                            </table>
                        </div>
                    </div>
                </div>
            }
            default:
                return (<Error />);
        }

    }
}