import * as React from 'react';
import { changeLanguage } from './Actions/I18n';
import { none } from './Actions/Modal';
import { goTo } from './Actions/Router';
import { sessionLogout } from './Actions/Session';
import { clean, error, info, success } from './Actions/Toastr';
import Loading from './Components/Loading';
import Endpoints from './Endpoints';
import { COMPANYUSERROLES, ORDER, PERMISSIONS, SUBSCRIPTIONTYPE, VIEWMODE } from './General';
import caches from './Reducers/Caches';
import i18n from './Reducers/I18n';
import modal from './Reducers/Modal';
import router from './Reducers/Router';
import screenSize from './Reducers/ScreenSize';
import session from './Reducers/Session';
import toastr from './Reducers/Toastr';
import Urls from './Urls';
import { getAnchor, getURLParameter } from './Utils';
import Validator, { Rules } from './Validator';

export default class Component extends React.Component<any, any> {
    static componentId = 0;
    cid: any;

    public static cid() {
        return Component.componentId++;
    }

    constructor(props?: any) {
        super(props);
        this.cid = props.cid ? this.props.cid : Component.cid();
    }

    Urls() {
        return Urls;
    }

    Validator() {
        return Validator;
    }

    Rules() {
        return Rules;
    }

    getSession() {
        return session;
    }

    getRouter() {
        return router;
    }

    getToastr() {
        return toastr;
    }

    getModal() {
        return modal;
    }

    getCaches() {
        return caches;
    }

    getScreenSize() {
        return screenSize;
    }

    getScreenSizeState(): any { return screenSize.getState(); }

    isScreenSizeSmall() { return Component.isScreenSizeSmall(screenSize.getState()); }

    isScreenSizeMd() { return this.getScreenSizeState().md; }

    isScreenSizeLg() { return this.getScreenSizeState().lg; }

    // tslint:disable-next-line:member-ordering
    static isScreenSizeSmall(screen: any) { return screen.sm || screen.xs; }

    goTo(target: string) {
        goTo(router, target);
    }

    goToLogin(options?: any) {
        goTo(router, Urls.getLogin(options));
    }

    goToLogout() { goTo(router, Urls.getLogout()); }

    goToLocalOAuth(code: string) { goTo(router, Urls.getLocalOAuth(code)); }

    goToDashboard() { goTo(router, Urls.getDashboard()); }

    goToUserNotAllowed() { goTo(router, Urls.getUserNotAllowed()); }

    goToUserNotFound() { goTo(router, Urls.getUserNotFound()); }

    goToUnits() { goTo(router, Urls.getUnits()); }

    goToEmployees() { goTo(router, Urls.getEmployees()); }

    goToEmployee(id: any) { goTo(router, Urls.getEmployee(id)); }

    goToLeaveTypes() { goTo(router, Urls.getLeaveTypes()); }

    goToNotificationRules() { goTo(router, Urls.getNotificationRules()); }

    translate() {
        return (i18n.getState() as any).translate;
    }

    language() {
        return (i18n.getState() as any).language;
    }

    errorToastr(message: any) {
        error(toastr, message);
    }

    generalErrorToastr() {
        error(toastr, this.translate()('a.general.an.error.occurred'));
    }

    successToastr(message: any) {
        success(toastr, message);
    }

    infoToastr(message: any) {
        info(toastr, message);
    }

    cleanToastr() {
        clean(toastr);
    }

    Endpoints() {
        return Endpoints;
    }

    ajaxError(jqXHR?: any, textStatus?: any, errorThrown?: any) {
        if (jqXHR.statusText === 'abort') { return; }
        if (jqXHR.status === 401) {
            none(this.getModal(), {});
            sessionLogout(this.getSession());
            const location = window.location + '';
            if (!getURLParameter('target')) { this.goToLogin({ target: location }); }
            return;
        }
        this.generalErrorToastr();
    }

    ajaxGeneralError(jqXHR?: any, textStatus?: any, errorThrown?: any) {
        this.generalErrorToastr();
    }

    renderLoading() {
        return (<Loading />);
    }

    changeLanguage(lang: any) {
        changeLanguage(i18n, lang);
    }

    getListColSize(viewMode?: VIEWMODE) {
        if (!viewMode) {
            return this.getScreenSizeState().listColSize;
        }
        if (viewMode === VIEWMODE.LIST) {
            return 'col-12';
        } else {
            return this.getScreenSizeState().listColSize;
        }
    }

    getMomentWithLocale() {
        return (i18n.getState() as any).moment;
    }

    getAnchor() {
        return getAnchor();
    }

    getCurrentTab(DEFAULTTAB: any, TABS: any) {
        const anchor = this.getAnchor();
        if (!anchor) {
            return DEFAULTTAB;
        }
        for (const item of Object.keys(TABS)) {
            if (item === anchor) {
                return item;
            }
        }
        return DEFAULTTAB;
    }

    compareString(a: any, b: any, order: any) {
        if (this.state.filters.orderBy.order === ORDER.ASC) {
            return (a < b ? -1 : (a > b ? 1 : 0));
        } else {
            return (b < a ? -1 : (b > a ? 1 : 0));
        }
    }

    getCardClassNameSize() {
        if (this.isScreenSizeSmall() || this.isScreenSizeMd()) {
            return 'card w-100 mb-3';
        }
        if (this.isScreenSizeLg()) {
            return 'card w-75';
        }
        return 'card w-50';
    }

    getUsersFilterByPermissions(permissionName: any) {
        const companyUser = (this.getSession().getState() as any).companyUser;
        const users = (this.getCaches().getState() as any).users.arr;
        const permission = (this.getSession().getState() as any).permissions[permissionName];
        switch (permissionName) {
            case PERMISSIONS.CALENDAR:
                if (this.isSimpleEmployee() || this.isManager()) {
                    switch (permission) {
                        case 0:
                            return users.filter((user: any) => {
                                if (user?.companyUnit?.managerCompanyUser?.id && companyUser.id) {
                                    return true;
                                }
                                if (user?.approverCompanyUser?.id === companyUser.id) {
                                    return true;
                                }
                                if (user?.office?.officeManager?.id && companyUser.id) {
                                    return true;
                                }
                                if (user.id === companyUser.id) {
                                    return true;
                                }
                                return false;
                            });
                        case 1:
                            return users.filter((user: any) => {
                                if (user.companyUnit && companyUser.companyUnit &&
                                    user.companyUnit.id === companyUser.companyUnit.id) {
                                    return true;
                                }
                                if (user.companyUnit && companyUser.companyUnit &&
                                    companyUser.companyUnit.managerCompanyUser &&
                                    user.id === companyUser.companyUnit.managerCompanyUser.id) {
                                    return true;
                                }
                                if (user.id === companyUser.approverCompanyUser.id) {
                                    return true;
                                }
                                if (user.approverCompanyUser && user.approverCompanyUser.id &&
                                    user.approverCompanyUser.id === companyUser.id) {
                                    return true;
                                }
                                return false;
                            });
                        case 3:
                            return users.filter((user: any) => {
                                if (user.office.id === companyUser.office.id) {
                                    return true;
                                }
                                if (user.companyUnit && companyUser.companyUnit &&
                                    companyUser.companyUnit.managerCompanyUser &&
                                    user.id === companyUser.companyUnit.managerCompanyUser.id) {
                                    return true;
                                }
                                if (user.id === companyUser.approverCompanyUser.id) {
                                    return true;
                                }
                                if (user.approverCompanyUser && user.approverCompanyUser.id &&
                                    user.approverCompanyUser.id === companyUser.id) {
                                    return true;
                                }
                                return false;
                            });
                        case 9:
                        default:
                            break;
                    }
                }
                if (this.isManager()) {
                    switch (permission) {
                        case 1:
                            return users.filter((user: any) => {
                                if (user.companyUser.approverCompanyUser.id === companyUser.id) {
                                    return true;
                                }
                                if (user.id === companyUser.approverCompanyUser.id) {
                                    return true;
                                }
                                if (companyUser.managerForCompanyUnits && companyUser.managerForCompanyUnits.length > 0) {
                                    for (let i = 0; i < companyUser.managerForCompanyUnits.length; i++) {
                                        if (user.companyUnit && companyUser.companyUnit &&
                                            user.companyUnit.id === companyUser.managerForCompanyUnits[i]) {
                                            return true;
                                        }
                                    }
                                }
                                return false;
                            });
                        case 3:
                        case 9:
                        default:
                            break;
                    }
                }
                break;
            case PERMISSIONS.REPORTS:
            default:
                if (this.isSimpleEmployee()) {
                    switch (permission) {
                        case 1:
                        case 3:
                        case 9:
                        default:
                            return [];
                    }
                }
                if (this.isManager()) {
                    switch (permission) {
                        case 1:
                            return users.filter((user: any) => {
                                if (user.companyUnit && companyUser.companyUnit &&
                                    user.companyUnit.id === companyUser.companyUnit.id) {
                                    return true;
                                }
                                if (user.approverCompanyUser && user.approverCompanyUser.id &&
                                    user.approverCompanyUser.id === companyUser.id) {
                                    return true;
                                }
                                return false;
                            });
                        case 3:
                        case 9:
                        default:
                    }
                }
                break;
        }
        return users;
    }

    getUnitsFilterByPermissions(permissionName: any) {
        const companyUser = (this.getSession().getState() as any).companyUser;
        const units = (this.getCaches().getState() as any).units.arr;
        const permission = (this.getSession().getState() as any).permissions[permissionName];
        switch (permissionName) {
            case PERMISSIONS.CALENDAR:
                if (this.isSimpleEmployee()) {
                    switch (permission) {
                        case 0: {
                            return units.filter((unit: any) => {
                                if (unit?.managerCompanyUser?.id === companyUser.id) {
                                    return true;
                                }
                                return false;
                            });
                        }
                        case 1:
                        case 3:
                            return [];
                        case 9:
                        default:
                    }
                }
                if (this.isManager()) {
                    switch (permission) {
                        case 1:
                        case 3:
                            return units.filter((unit: any) => {
                                if (companyUser.managerForCompanyUnits && companyUser.managerForCompanyUnits.length > 0) {
                                    for (let i = 0; i < companyUser.managerForCompanyUnits.length; i++) {
                                        if (unit.id === companyUser.managerForCompanyUnits[i]) {
                                            return true;
                                        }
                                    }
                                }
                                return false;
                            });
                        case 9:
                        default:
                    }
                }
                break;
            case PERMISSIONS.REPORTS:
            default:
                if (this.isSimpleEmployee()) {
                    switch (permission) {
                        case 1:
                        case 3:
                            return [];
                        case 9:
                        default:
                    }
                }
                if (this.isManager()) {
                    switch (permission) {
                        case 1:
                        case 3:
                            return units.filter((unit: any) => {
                                // tslint:disable-next-line:max-line-length
                                if (companyUser.managerForCompanyUnits && companyUser.managerForCompanyUnits.length > 0) {
                                    for (let i = 0; i < companyUser.managerForCompanyUnits.length; i++) {
                                        if (unit.id === companyUser.managerForCompanyUnits[i]) {
                                            return true;
                                        }
                                    }
                                }
                                return false;
                            });
                        case 9:
                        default:
                    }
                }
                break;
        }
        return units;
    }

    getOfficesFilterByPermissions(permissionName: any) {
        const companyUser = (this.getSession().getState() as any).companyUser;
        const offices = (this.getCaches().getState() as any).offices.arr;
        const subsidiaries = (this.getCaches().getState() as any).subsidiaries.arr;
        const permission = (this.getSession().getState() as any).permissions[permissionName];
        switch (permissionName) {
            case PERMISSIONS.CALENDAR:
                if (this.isSimpleEmployee() || this.isManager()) {
                    switch (permission) {
                        case 0: {
                            return offices.filter((office: any) => {
                                if (office?.officeManager?.id === companyUser.id) {
                                    return true;
                                }
                                return false;
                            });
                        }
                        case 1:
                            return [];
                        case 3:
                            return offices.filter((office: any) => { return office.id === (this.getSession().getState() as any).companyUser.office.id; });
                        case 4:
                            return subsidiaries.filter((subsidiary: any) => { return subsidiary.id === (this.getSession().getState() as any).companyUser.subsidiary.id; });
                        case 9:
                        default:
                    }
                }
                break;
            case PERMISSIONS.REPORTS:
            default:
                if (this.isSimpleEmployee() || this.isManager()) {
                    switch (permission) {
                        case 1:
                            return [];
                        case 3:
                            return offices.filter((office: any) => { return office.id === (this.getSession().getState() as any).companyUser.office.id; });
                        case 4:
                            return subsidiaries.filter((subsidiary: any) => { return subsidiary.id === (this.getSession().getState() as any).companyUser.subsidiary.id; });
                        case 9:
                        default:
                    }
                }
                break;
        }
        return offices;
    }

    getSubsidiariesFilterByPermissions(permissionName: any) {
        if (!(this.getSession().getState() as any).company.subsidiariesEnabled) {
            return [];
        }
        const companyUser = (this.getSession().getState() as any).companyUser;
        const subsidiaries = (this.getCaches().getState() as any).subsidiaries.arr;
        const permission = (this.getSession().getState() as any).permissions[permissionName];
        switch (permissionName) {
            case PERMISSIONS.CALENDAR:
                if (this.isSimpleEmployee() || this.isManager()) {
                    switch (permission) {
                        case 0: {
                            return subsidiaries.filter((subsidiary: any) => {
                                if (subsidiary?.manager?.id === companyUser.id) {
                                    return true;
                                }
                                return false;
                            });
                        }
                        case 1:
                            return [];
                        case 3:
                            return subsidiaries.filter((subsidiary: any) => { return subsidiary.id === (this.getSession().getState() as any).companyUser.subsidiary.id; });
                        case 4:
                            return subsidiaries.filter((subsidiary: any) => { return subsidiary.id === (this.getSession().getState() as any).companyUser.subsidiary.id; });
                        case 9:
                        default:
                    }
                }
                break;
            case PERMISSIONS.REPORTS:
            default:
                if (this.isSimpleEmployee() || this.isManager()) {
                    switch (permission) {
                        case 1:
                            return [];
                        case 3:
                            return subsidiaries.filter((subsidiary: any) => { return subsidiary.id === (this.getSession().getState() as any).companyUser.subsidiary.id; });
                        case 4:
                            return subsidiaries.filter((subsidiary: any) => { return subsidiary.id === (this.getSession().getState() as any).companyUser.subsidiary.id; });
                        case 9:
                        default:
                    }
                }
                break;
        }
        return subsidiaries;
    }

    isSimpleEmployee() {
        const role = (this.getSession().getState() as any).companyUser.role;
        const companyUser = (this.getSession().getState() as any).companyUser;
        const isManager = companyUser.isManager;
        return role === COMPANYUSERROLES.COMPANY_USER && !isManager;
    }

    isManager() {
        const role = (this.getSession().getState() as any).companyUser.role;
        const companyUser = (this.getSession().getState() as any).companyUser;
        const isManager = companyUser.isManager;
        return role === COMPANYUSERROLES.COMPANY_USER && isManager;
    }

    trialHasEnded() {
        const company: any = (this.getSession().getState() as any).company;
        const moment = this.getMomentWithLocale();
        if (SUBSCRIPTIONTYPE.TRIAL === company.subscriptionType) {
            if (moment().isAfter(moment(company.endsOn))) {
                this.goTo(this.Urls().getSubscribeNow(true));
                return true;
            }
        }
        return false;
    }

    paymentRequired() {
        if (this.isPaymentRequired()) {
            this.goTo(this.Urls().getSubscribeNow());
            return true;
        }
        return false;
    }

    isPaymentRequired() {
        const company: any = (this.getSession().getState() as any).company;
        return !!company.paymentRequired;
    }

    paymentWarning() {
        const company: any = (this.getSession().getState() as any).company;
        return !!company.paymentWarning;
    }

    currentBusinessYear() {
        const businessYearStartMonth = (this.getSession().getState() as any).company.businessYearStartMonth;
        const businessYearStartDay = (this.getSession().getState() as any).company.businessYearStartDay;
        const currentMonth = this.getMomentWithLocale()().month() + 1;
        const currentDate = this.getMomentWithLocale()().date();
        if ((this.getSession().getState() as any).company.id >= 23776) {
            if (businessYearStartMonth > 6) {
                if (currentMonth >= businessYearStartMonth) {
                    return this.getMomentWithLocale()().subtract('year', -1)
                        .month(businessYearStartMonth)
                        .date(businessYearStartDay)
                        .startOf('day').year();
                }
            }
            return this.getMomentWithLocale()().year();
        }
        if ((currentMonth < businessYearStartMonth) || (currentMonth === businessYearStartMonth && currentDate < businessYearStartDay)) {
            return this.getMomentWithLocale()().subtract('year', 1)
                .month(businessYearStartMonth)
                .date(businessYearStartDay)
                .startOf('day').year();
        }
        return this.getMomentWithLocale()().year();
    }

    getDateFormat() {
        let dateFormat: any = (this.getSession().getState() as any)?.companyUser?.dateFormat;
        dateFormat = dateFormat === null ? 'L' : dateFormat;
        dateFormat = dateFormat === 'browser.default' ? 'L' : dateFormat;
        return dateFormat;
    }
}