import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import Line from '../../../Components/Line';
import { fullNameFactory } from '../../../Factories';
import { LEAVESTATUS, ORDER, REPORTSTABS, STATUS, USERSTATUS } from '../../../General';
import Tabs from '../Tabs';
import Download from './Download';
import Filters from './Filters';
import Table from './Table';

export default class Manager extends Component {
    search: any;
    computeRows: any;
    constructor(props?: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            filters: {
                orderBy: { field: 'DATE', order: ORDER.ASC },
                users: {
                    value: [],
                },
                units: {
                    value: [],
                },
                leaveStatuses: {
                    value: [LEAVESTATUS.AWAITING_APPROVAL, LEAVESTATUS.APPROVED]
                },
                leaveTypes: {
                    value: [],
                },
                approvers: {
                    value: [],
                },
                startDate: { value: moment().startOf('day').startOf('month') },
                endDate: { value: moment().startOf('day').endOf('month') }
            },
            rows: {
                arr: [],
                status: STATUS.LOADING
            }
        };
        this.search = Manager.search.bind(this);
        this.computeRows = Manager.computeRows.bind(this);
    }

    componentDidMount() { this.search(); }

    public static search(this: any) {
        this.setState(
            {
                leaves: {
                    arr: [],
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getReportsLeaves({
                        filters: this.state.filters
                    }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                rows: {
                                    arr: this.computeRows(data).sort((a: any, b: any) => {
                                        const compare = a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
                                        if (compare !== 0) {
                                            return compare;
                                        }
                                        return this.compareString(a[3], b[3], this.state.filters.orderBy.order);
                                    }),
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                rows: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    public static computeRows(this: any, data: any) {
        const moment = this.getMomentWithLocale();
        const rows: any = [];
        const caches: any = this.getCaches().getState();
        const usersMap = caches.users.map;
        let leaveTypesMap = caches.leaveTypes.map;
        const dateFormat = this.getDateFormat();
        const session = this.getSession().getState() as any;
        data.filter((row: any) => {
            return usersMap[row[0]].status !== USERSTATUS.DISABLED;
        }).forEach((item: any) => {
            let row: any = item;
            let startDate = moment(item[3]);
            startDate = startDate.isSameOrBefore(this.state.filters.startDate.value) ?
                this.state.filters.startDate.value : startDate;
            let endDate = moment(item[4]);
            endDate = endDate.isSameOrAfter(this.state.filters.endDate.value) ?
                this.state.filters.endDate.value : endDate;
            row.interval = (item[3] === item[4]) ?
                startDate.format(dateFormat)
                : startDate.format(dateFormat) + ' - ' + endDate.format(dateFormat);
            row.employee = {
                firstName: item[1],
                lastName: item[2]
            };
            row.employee.fullName = fullNameFactory(row.employee, session.company.nameFormat);
            row.employee.email = usersMap[row[0]].email;
            if (item[9]) {
                row.approver = {
                    firstName: item[9],
                    lastName: item[10]
                };
                row.approver.fullName = fullNameFactory(row.approver, session.company.nameFormat);
            } else {
                if (item[11]) {
                    row.approver = {
                        firstName: item[11],
                        lastName: item[12]
                    };

                }
            }
            row.leaveType = leaveTypesMap[item[5]];
            row.comment = item[13];
            row.approverComment = item[14];
            row.cancelComment = item[15];
            row.requestedOn = item[16];
            rows.push(row);
        });
        return rows;
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Tabs
                            currentTab={REPORTSTABS.LEAVES}
                            onChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <Filters
                    download={(new Download({ filters: this.state.filters }))}
                    defaultStartDate={this.state.filters.startDate.value}
                    defaultEndDate={this.state.filters.endDate.value}
                    defaultLeaveStatuses={this.state.filters.leaveStatuses.value}
                    defaultLeaveTypes={this.state.filters.leaveTypes.value}
                    viewMode={this.state.filters.viewMode}
                    orderBy={this.state.filters.orderBy}
                    onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
                    onTabChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }}
                />
                <div className="row">
                    <div className="col-12 mb-3">
                        <Table rows={this.state.rows} onRefresh={this.search} />
                    </div>
                </div>
            </div>
        );
    }
}