import * as React from 'react';
import Component from '../../../Component';
import Employee from '../../../Components/Employee';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import LeaveName from '../LeaveName';

export enum ANNUALROWTYPE {
    EMPLOYEE = 'EMPLOYEE',
    YEAR = 'YEAR'
}

export default class Table extends Component {

    render() {
        const rows = this.props.rows;
        switch (rows.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                if (rows.arr.length === 0) {
                    return (<div className="text-danger">{t('no.records.found')}</div>);
                }
                var map: { [key: number]: any; } = {};
                rows.arr.forEach((row: any) => {
                    if (map[row[0]]) {
                        if (map[row[0]].years[row[4]]) {
                            map[row[0]].years[row[4]].push({
                                leaveTypeId: row[3],
                                allocation: row[5],
                                amount: row[6]
                            });
                        } else {
                            map[row[0]].years[row[4]] = [].concat({
                                leaveTypeId: row[3],
                                allocation: row[5],
                                amount: row[6]
                            } as any);
                        }
                    } else {
                        map[row[0]] = {
                            companyUserId: row[0],
                            firstName: row[1],
                            lastName: row[2],
                            years: {}
                        };
                        map[row[0]].years[row[4]] = [].concat({
                            leaveTypeId: row[3],
                            allocation: row[5],
                            amount: row[6]
                        } as any);
                    }

                });
                //                var colcount = 2;
                let thead = <thead key='thead' className="sticky-top bg-white">
                    <tr key='header-1'>
                        <th rowSpan={2} className="border-top-0" style={{ borderRight: '1px solid #DEE2E6', maxWidth: '150px', top: 0, left: 0, position: 'sticky', zIndex: 1, background: '#FFFFFF' }} />
                        <th className="border-bottom-0">
                        </th>
                        {this.props.leaveTypes.map((leaveType: any) => {
                            return <th
                                className="border-bottom-0 border-left border-right"
                                colSpan={3 + (leaveType.showEarnedAllowance && this.props.isCurrentYear ? 1 : 0)}
                                key={'ReportsAnnualTableTHeadTh-' + leaveType.id}
                            >
                                <div className="float-right"><LeaveName leave={{ leaveType: leaveType }} /></div>
                            </th>
                        })}
                    </tr>
                    <tr key='header-2'>
                        <th
                            className="text-right border-top-0 border-bottom-0 text-nowrap">
                            {t('year')}
                        </th>
                        {this.props.leaveTypes.map((leaveType: any) => {
                            return <>
                                <th
                                    className="text-right border-top-0 border-bottom-0 border-left text-nowrap"
                                    key={'ReportsAnnualTableTHeadThDaysUsed-' + leaveType.id}
                                >
                                    {t('used')}
                                </th>
                                <th
                                    className="text-right border-0"
                                    key={'ReportsAnnualTableTHeadThAvailable-' + leaveType.id}
                                >
                                    {t('available')}
                                </th>
                                {leaveType.showEarnedAllowance && this.props.isCurrentYear ?
                                    <th
                                        className="text-right border-0"
                                        key={'ReportsAnnualTableTHeadThAllowanceEarned-' + leaveType.id}
                                    >
                                        {t('earned')}
                                    </th> : null}
                                <th
                                    className="text-right border-top-0 border-bottom-0 border-right"
                                    key={'ReportsAnnualTableTHeadThAllowance-' + leaveType.id}
                                >
                                    {t('allowance')}
                                </th>
                            </>
                        })}
                    </tr>
                </thead>;
                let currentEmployee: any;
                const tbodyTrs = rows.arr.map((row: any) => {
                    switch (row.type) {
                        case ANNUALROWTYPE.EMPLOYEE: {
                            currentEmployee = row.employee;
                            break;
                        }
                        case ANNUALROWTYPE.YEAR: {
                            return <tr key={'ReportsAnnualSituationTableTrEmployee-' + row.id + '-' + row.year}>
                                <td className="text-truncate border-0 shadow"
                                    style={{ borderRight: '1px solid #DEE2E6', maxWidth: '150px', top: 0, left: 0, position: 'sticky', zIndex: 1, background: '#FFFFFF' }}
                                >
                                    <Employee employee={currentEmployee} />
                                </td>
                                <td className="text-right border-bottom-0 border-left border-secondary text-nowrap">
                                    {row.year}
                                </td>
                                {row.leaveTypes.map((leaveType: any) => {
                                    return <>
                                        <td
                                            key={'ReportsAnnualSituationTableTrYearAmount-' +
                                                row.id + '-' + row.year + '-' + leaveType.id}
                                            className="text-right border-bottom-0 border-left border-secondary text-nowrap"
                                        >
                                            {leaveType.amount}
                                        </td>
                                        <td
                                            key={'ReportsAnnualSituationTableTrYearAvaiable-' +
                                                row.id + '-' + row.year + '-' + leaveType.id}
                                            className="text-right border-bottom-0 border-secondary border-left-0 border-right-0 text-nowrap"
                                        >
                                            {leaveType.allocation - leaveType.amount - leaveType.transferredToNextYear}
                                        </td>
                                        {leaveType.leaveType.showEarnedAllowance && this.props.isCurrentYear ?
                                            <td
                                                key={'ReportsAnnualSituationTableTrYearEarned-' +
                                                    row.id + '-' + row.year + '-' + leaveType.id}
                                                className="text-right border-bottom-0 border-secondary border-left-0 border-right-0 text-nowrap"
                                            >
                                                {this.getEmployeeEarnedTotalAllowance(leaveType.yearlyAllowance, leaveType.supplemental, leaveType.receivedFromLastYear, leaveType.pastYearDays, leaveType.totalYearDays, row.employee.id)}
                                            </td>
                                            : null
                                        }
                                        <td
                                            key={'ReportsAnnualSituationTableTrYearAllocation-' +
                                                row.id + '-' + row.year + '-' + leaveType.id}
                                            className="text-right border-bottom-0 border-right border-secondary"
                                        >
                                            {leaveType.allocation}
                                        </td>
                                    </>
                                })
                                }
                            </tr>
                        }
                        default:
                            return null;
                    }
                    return null;
                });
                return <div className="card w-100">
                    <div className="card-body">
                        <div className="table-responsive-sm">
                            <table className="table table-sm table-hover w-100 mb-0">
                                {thead}
                                <tbody>
                                    {tbodyTrs}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            default:
                return (<Error />);
        }
    }

    getEmployeeTotalAllowance(allowance: any, adjustment: any, receivedFromLastYear: any) {
        const value = allowance + adjustment + (receivedFromLastYear ? receivedFromLastYear : 0);
        return value;
    }

    getEmployeeEarnedTotalAllowance(yearlyAllowance: any, adjustment: any, receivedFromLastYear: any, pastDaysFromStartOfYear: any, totalYearDays: any, userId: any) {
        if (pastDaysFromStartOfYear && pastDaysFromStartOfYear < 1) {
            return 0;
        }
        const value = this.getEmployeeEarnedAllowance(totalYearDays < 365 ? yearlyAllowance + adjustment : yearlyAllowance, pastDaysFromStartOfYear, totalYearDays)
            + (totalYearDays < 365 ? 0 : adjustment) + (receivedFromLastYear ? receivedFromLastYear : 0);
        return value;
    }

    getEmployeeEarnedAllowance(yearlyAllowance: any, pastDaysFromStartOfYear: any, totalYearDays: any) {
        const value = Math.round(yearlyAllowance * pastDaysFromStartOfYear / totalYearDays);
        return value;
    }
}