import * as React from 'react';
import Component from '../../../Component';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import THead from './THead';
import Tr from './Tr';

export default class Table extends Component {

    render() {
        const moment = this.getMomentWithLocale();
        const rows = this.props.rows;
        switch (rows.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                if (rows.arr.length === 0) {
                    return <div className="text-danger">
                        {t('no.records.found')}
                    </div>
                }
                let map: any = {};
                map.working = 0;
                map.worked = 0;
                this.props.leaveTypes.forEach((leaveType: any) => {
                    map[leaveType.id] = 0;
                });
                const endDate = moment(this.props.endDate);
                const startDate = moment(this.props.startDate);
                const tbodyTRs = rows.arr.map((row: any) => {
                    const employmentEndDate = row.employmentEndDate;
                    const employmentStartDate = row.employmentStartDate;
                    let totalDaysNumber: any = moment(employmentEndDate && employmentEndDate.valueOf() < endDate.valueOf() ? employmentEndDate : endDate)
                        .diff(moment(employmentStartDate && employmentStartDate.valueOf() > startDate.valueOf() ? employmentStartDate : startDate), 'days') + 1;
                    if (totalDaysNumber < 0)
                        totalDaysNumber = 0;
                    let working = (totalDaysNumber - row.notWorkingAmount);
                    map.working += working;
                    let workedAmount = (totalDaysNumber - row.notWorkingAmount);
                    const leaveTypeIdAmounts = this.props.leaveTypes.map((leaveType: any) => {
                        let amount: number = 0;
                        for (let i = 0; i < row.generalReportLeaveTypeAmounts.length; i++) {
                            if (leaveType.id === row.generalReportLeaveTypeAmounts[i].leaveTypeId) {
                                amount = row.generalReportLeaveTypeAmounts[i].amount;
                                if (!leaveType.working) {
                                    workedAmount -= row.generalReportLeaveTypeAmounts[i].amount;
                                }
                                break;
                            }
                        }
                        map[leaveType.id] += amount;
                        return {
                            leaveTypeId: leaveType.id,
                            amount: amount
                        };
                    });
                    map.worked += workedAmount;
                    return <Tr
                        key={'ReportsGeneralTableTBodyTr-' + row.id}
                        row={row}
                        working={working}
                        workedAmount={workedAmount}
                        leaveTypeIdAmounts={leaveTypeIdAmounts}
                    />
                });
                return <div className="card w-100">
                    <div className="card-body">
                        <div className="table-responsive-sm">
                            <table className="table table-sm table-hover w-100 mb-0">
                                <THead
                                    leaveTypes={this.props.leaveTypes}
                                />
                                <tbody>
                                    {tbodyTRs}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="text-truncate border-0 shadow"
                                            style={{ borderRight: '1px solid #DEE2E6', maxWidth: '150px', top: 0, left: 0, position: 'sticky', zIndex: 1, background: '#FFFFFF' }}
                                        />
                                        <td className="text-right font-weight-bold">{map.working}</td>
                                        <td className="text-right font-weight-bold">{map.worked}</td>
                                        {this.props.leaveTypes.map((leaveType: any) => {
                                            return <td key={'ReportsGeneralTableTFootTRTD-' + leaveType.id} className="text-right font-weight-bold">
                                                {map[leaveType.id] ? map[leaveType.id] : '0'}
                                            </td>
                                        })}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            }
            default:
                return (<Error />);
        }
    }
}