import Component from '../../../Component';
import Employee from '../../../Components/Employee';
import Error from '../../../Components/Error';
import LeaveDecisionActionTiny from '../../../Components/Leave/LeaveDecisionActionTiny';
import LeaveName from '../../../Components/Leave/LeaveName';
import LeaveStatus from '../../../Components/Leave/LeaveStatus';
import { COMPANYUSERROLES, LEAVESTATUS, STATUS } from '../../../General';
import Comment from './Comment';

export default class List extends Component {

    render() {
        const caches: any = this.getCaches().getState();
        let leaveTypesMap = caches.leaveTypes.map;
        const rows = this.props.rows;
        const isAdmin = (this.getSession().getState() as any).companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        const moment = this.getMomentWithLocale();
        switch (rows.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                if (rows.arr.length === 0) {
                    return (
                        <div className="text-danger">
                            {t('no.leaves.found')}
                        </div>
                    );
                }
                let currentUserId = 0;
                const trs = rows.arr.map((row: any) => {
                    let user: any = '';
                    if (currentUserId !== row[0]) {
                        currentUserId = row[0];
                        user = (
                            <tr key={'ReportsLeavesItemUser' + row[0] + '-' + row[3] + '-' + row[4]}>
                                <td colSpan={7} className="border-top border-secondary">
                                    <Employee employee={row.employee} />
                                </td>
                            </tr>
                        );
                    }
                    const tr = (
                        <tr key={'ReportsLeavesItem' + row[0] + '-' + row[3] + '-' + row[4] + '-' + row[8]} >
                            <td style={{ width: '150px', maxWidth: '150px' }} />
                            <td className="text-nowrap">
                                <LeaveName leave={{ leaveType: leaveTypesMap[row[5]] }} />
                                {row.comment && row.comment.length > 0 ?
                                    <Comment comment={row.comment} type="REQUEST" /> : null}
                            </td>
                            <td className="text-right text-nowrap">
                                {row.interval}
                            </td>
                            <td className="text-right text-nowrap">{row[6]}</td>
                            <td className="text-right text-nowrap">
                                <LeaveStatus leave={{ status: row[7] }} />
                                {
                                    (row[7] === 1000 && isAdmin) ?
                                        <LeaveDecisionActionTiny leave={{ id: row[8] }} onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }} />
                                        :
                                        null
                                }
                                {(row.cancelComment && row.cancelComment.length > 0 &&
                                    row[7] === LEAVESTATUS.CANCELED) ?
                                    <Comment comment={row.cancelComment} type="CANCEL" /> : null}
                                {(row.approverComment && row.approverComment.length > 0 &&
                                    row[7] === LEAVESTATUS.APPROVED) ?
                                    <Comment comment={row.approverComment} type="APPROVED" /> : null}
                                {(row.approverComment && row.approverComment.length > 0 &&
                                    row[7] === LEAVESTATUS.REJECTED) ?
                                    <Comment comment={row.approverComment} type="REJECTED" /> : null}
                            </td>
                            {
                                (row.approver) ?
                                    <td className="text-right text-nowrap">
                                        <div className="float-right"><Employee employee={row.approver} /></div>
                                    </td>
                                    :
                                    <td />
                            }
                            <td className="text-right text-nowrap">{moment(row.requestedOn).format('LLL')}</td>
                        </tr>
                    );
                    return user.length === 0 ? tr : [user, tr];
                });

                return <div className="card w-100">
                    <div className="card-body">
                        <div className="table-responsive-sm">
                            <table className="table table-sm table-hover w-100 mb-0">
                                <thead className="sticky-top bg-white">
                                    <tr>
                                        <th className="border-0" style={{ width: '150px', maxWidth: '150px' }} />
                                        <th className="border-0">
                                            {t('leave')}
                                        </th>
                                        <th className="border-0 text-right">
                                            {t('range')}
                                        </th>
                                        <th className="border-0 text-right">
                                            {t('days')}
                                        </th>
                                        <th className="border-0 text-right">
                                            {t('status')}
                                        </th>
                                        <th className="border-0 text-right">
                                            {t('approver')}
                                        </th>
                                        <th className="border-0 text-right">
                                            {t('registered.on')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trs}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
            default:
                return (<Error />);
        }

    }
}