import * as React from 'react';
import Component from '../../../Component';
import LeaveName from '../LeaveName';

export default class THead extends Component {

    render() {
        const t = this.translate();
        return <thead className="sticky-top bg-white">
            <tr>
                <th className="text-right border-0 shadow"
                    style={{ borderRight: '1px solid #DEE2E6', maxWidth: '150px', top: 0, left: 0, position: 'sticky', zIndex: 1, background: '#FFFFFF' }} />
                <th className="text-right border-0">
                    {t('working')}
                </th>
                <th className="text-right border-0">
                    {t('worked')}
                </th>
                {this.props.leaveTypes.map((leaveType: any) => {
                    return (
                        <th className="border-0" key={'ReportsGeneralTableTHeadTh-' + leaveType.id}>
                            <div className="float-right">
                                <LeaveName leave={{ leaveType: leaveType }} />
                            </div>
                        </th>
                    );
                })}
            </tr>
        </thead>
    }
}